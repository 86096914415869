import React from 'react';
import classNames from 'classnames';
import {
  InputGroup,
  DatePicker,
  InputGroupAddon,
  InputGroupText
} from 'shards-react';
import moment from 'moment-timezone';

import '../../assets/range-date-picker.css';

class RangeDatePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: undefined,
      endDate: undefined
    };

    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
  }

  componentDidUpdate(props) {
    if (this.props.dates !== props.dates) {
      const dates = this.props.dates || {}
      const startAt = dates.priorityStartAt || dates.startAt || null
      const endAt = dates.priorityEndAt || dates.endAt || null

      this.setState({
        startAt: endAt && !startAt ? '2001-01-01' : startAt,
        endAt: startAt && !endAt ? moment().format('YYYY-MM-DD') : endAt
      })
    }

    if (this.props.clear && this.props.clear !== props.clear) {
      this.setState({
        startAt: null,
        endAt: null,
        startDate: null,
        endDate: null
      })
    }
  }

  handleStartDateChange(value) {
    this.setState({
      ...this.state,
      ...{ startDate: new Date(value) }
    });
    this.props.onStartAt(moment(value).format('YYYY-MM-DD'))
  }

  handleEndDateChange(value) {
    this.setState({
      ...this.state,
      ...{ endDate: new Date(value) }
    });
    this.props.onEndAt(moment(value).format('YYYY-MM-DD'))
  }

  render() {
    const { className } = this.props;
    const classes = classNames(className, 'd-flex', 'my-auto', 'date-range');

    return (
      <InputGroup className={classes} style={{ ...this.props.style }}>
        <DatePicker
          size='md'
          dateFormat="yyyy-MM-dd"
          selected={this.state.startDate}
          onChange={this.handleStartDateChange}
          placeholderText={this.state.startAt || this.state.placeholderStartAt || 'Start Date'}
          dropdownMode='select'
          className='text-center'
        />
        <DatePicker
          size='md'
          dateFormat="yyyy-MM-dd"
          selected={this.state.endDate}
          onChange={this.handleEndDateChange}
          placeholderText={this.state.endAt || this.state.placeholderEndAt || 'End Date'}
          dropdownMode='select'
          className='text-center'
        />
        <InputGroupAddon type='append'>
          <InputGroupText>
            <i className='material-icons'>&#xE916;</i>
          </InputGroupText>
        </InputGroupAddon>
      </InputGroup>
    );
  }
}

export default RangeDatePicker;
