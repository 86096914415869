import React from 'react';
import {
    Container, Button, ButtonGroup, CardBody, Badge, FormCheckbox
} from "shards-react";
import * as moment from 'moment-timezone';
import { fetchDownload, fetchGet, fetchPost } from '../../../utils/api';
import { withRouter } from 'react-router-dom';
import HTMLInvoice from '../../../utils/invoice';

class InvoiceTable extends React.Component {
    state = {
        selected: [],
        status: 'invoice_generated',
        name: '',
        dates: [],
        count: 0
    }

    componentDidMount() {
        if (this.props.status) {
            this.setState({ status: this.props.status })
        }
        if (this.props.count) {
            this.setState({ count: this.props.count })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.status !== prevProps.status) {
            this.setState({ status: this.props.status })
        }
        if (this.props.refresh !== prevProps.refresh) {
            this.setState({ selected: [] })
        }
        if (this.props.count !== prevProps.count) {
            this.setState({ count: this.props.count })
        }
        if (this.props.selectAll !== prevProps.selectAll) {
            if (this.props.selectAll) {
                const list = this.props.data.map(invoice => invoice.uuid)
                const dates = this.props.data.map(invoice => invoice.createdAt)

                this.setState({ selected: list })
                this.props.onSelected(list, dates)
            } else {
                this.setState({ selected: [] })
                this.props.onSelected([], [])
            }
        }
    }

    renderAdditional(additional) {
        switch (additional) {
            case 'spaces':
                return <Badge theme="warning" className='mr-2 px-2 status-badge' style={{ fontSize: '14px' }}>Spaces</Badge>
            case 'yes':
                return <Badge theme="danger" className='mr-2 px-2 status-badge' style={{ fontSize: '14px' }}>Yes</Badge>
            default:
                return <Badge theme="secondary" className='mr-2 px-2 status-badge' style={{ fontSize: '14px' }}>No</Badge>
        }
    }

    downloadInvoice(row, btn) {
        var api = '/utils/export/invoice/' + row['uuid']
        var name = `${row['invoice_no']}_${moment(row['created_at']).format('YYYY-MM-DD HH:mm')}.xlsx`
        fetchDownload(api,
            (response) => {
                const blob = response
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = name;
                a.click();
                btn.disabled = false
            },
            () => {
            }
        );
    }

    onRevert(uuid, job) {
        var api = '/jobs/revert/' + uuid
        fetchPost(api,
            {
                job_no: job
            },
            (response) => {
                this.props.onReverted(0)
            },
            () => {
            }
        );
    }

    onArchive(uuid) {
        var api = '/invoices/archive/' + uuid
        fetchGet(api,
            (response) => {
                this.props.onReverted(1)
            },
            () => {
            }
        );
    }

    renderGenerated() {
        const page = this.props.page
        const count = this.state.count
        const multiplier = count < 12 ? count : 12
        const max = count < page * multiplier ? count : page * multiplier

        if (this.props.data && this.props.data.length === 0) {
            return (
                <Container fluid className="main-content-container px-4 pb-4">
                    <div className="error__content mt-4">
                        <h2>Empty</h2>
                        <h3>No Invoices Found</h3>
                        <p>It could be that there are no complete service calls. </p>
                    </div>
                </Container>
            );
        }

        return (
            <CardBody className="p-0 pb-3">
                <table className="table mb-0">
                    <thead className="bg-light">
                        <tr>
                            <th scope="col" width='1%' className="border-0 hide-on-mobile"></th>
                            <th scope="col" width='11%' className="border-0 hide-on-tablet">Date</th>
                            <th scope="col" width='15%' className="border-0 hide-on-tablet">Additional Items</th>
                            <th scope="col" width='10%' className="border-0">Invoice No.</th>
                            <th scope="col" width='8%' className="border-0">Job No.</th>
                            <th scope="col" width='20%' className="border-0 hide-on-mobile">Agent</th>
                            <th scope="col" width='10%' className="border-0 hide-on-tablet">Amount</th>
                            <th scope="col" width='25%' className="border-0">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.data.map((row, idx) => {
                            var list = this.state.selected
                            var dates = this.state.dates
                            const checked = this.state.selected.find(x => x === row.uuid)
                            return (
                                <tr id={row['uuid']} key={row['uuid']} className='py-1 px-1'>
                                    <td className='hide-on-mobile pt-2 pl-3'>
                                        <div className='mx-3 my-0 px-2 py-1'>
                                            <FormCheckbox
                                                checked={!!checked}
                                                style={{ width: '4rem' }}
                                                value={!!checked}
                                                onChange={() => {
                                                    if (!!checked) {
                                                        list = list.filter(item => item !== row['uuid'])
                                                        dates = dates.filter(item => item !== row['createdAt'])
                                                        this.setState({
                                                            selected: list
                                                        })
                                                        this.props.onSelected(list, dates)
                                                    } else {
                                                        list.push(row.uuid)
                                                        dates.push(row.createdAt)
                                                        this.setState({
                                                            selected: list
                                                        })
                                                        this.props.onSelected(list, dates)
                                                    }
                                                }}
                                            />
                                        </div>
                                    </td>
                                    <td className='hide-on-tablet pt-3 pl-3'>
                                        {moment(row['created_at']).format('YYYY-MM-DD HH:mm')}
                                    </td>
                                    <td className='hide-on-tablet pt-3'>
                                        {this.renderAdditional(row['additional_line_item'])}
                                    </td>
                                    <td className='pt-3'>{row['invoice_no']}</td>
                                    <td className='pt-3'>{row['job_no']}</td>
                                    <td className='hide-on-mobile pt-3'>
                                        {row['agent'] ? row['agent']['full_name'] : ''}
                                    </td>
                                    <td className='hide-on-tablet pt-3'>R {parseFloat(row['total'] || 0).toFixed(2)}</td>
                                    <td>
                                        <ButtonGroup size="md" className="mr-0 ">
                                            <Button
                                                className='btn-yellow'
                                                onClick={() => this.props.history.push(`/invoices/${row['uuid']}/view`, {
                                                    row: row,
                                                    currentState: this.props.currentState,
                                                    scroll: row['uuid']
                                                })}
                                            >
                                                View
                                            </Button>
                                            <Button
                                                className='btn-primary'
                                                onClick={() => this.props.onEdit(row)}
                                            >
                                                Edit
                                            </Button>
                                            <Button
                                                className='btn-danger'
                                                type='button'
                                                onClick={() => this.props.onShowRevertPopup(row['details'])}
                                            >
                                                Revert
                                            </Button>
                                        </ButtonGroup>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <div className='bg-light d-flex text-center mt-2 px-3' style={{ width: '100%', height: '2rem', display: 'none' }}>
                    <div className='mx-2'>
                        <p align="center" valign="center" style={{ lineHeight: '2rem' }}>
                            Displaying {((page - 1) * 12) + 1} to {max} {count ? ` of ${count} ` : ''} results
                        </p>
                    </div>
                    <ButtonGroup className="mr-2 ">
                        {(page > 1) && <Button theme='secondary' type='button' onClick={() => this.props.onPrev()}>Prev</Button>}
                        <div style={{ width: '8px' }} />
                        {(count >= page * 12) && <Button theme='secondary' type='button' onClick={() => this.props.onNext()}>Next</Button>}
                    </ButtonGroup>
                </div>
            </CardBody>
        )
    }

    renderSubmitted() {
        const page = this.props.page
        const count = this.state.count
        const multiplier = count < 12 ? count : 12
        const max = count < page * multiplier ? count : page * multiplier

        if (this.props.data && this.props.data.length === 0) {
            return (
                <Container fluid className="main-content-container px-4 pb-4">
                    <div className="error__content mt-4">
                        <h2>Empty</h2>
                        <h3>No Invoices Found</h3>
                        <p>It could be that there are no complete service calls. </p>
                    </div>
                </Container>
            );
        }

        return (
            <CardBody className="p-0 pb-3">
                <table className="table mb-0">
                    <thead className="bg-light">
                        <tr>
                            <th scope="col" width='5%' className="border-0 hide-on-mobile"></th>
                            <th scope="col" width='15%' className="border-0 hide-on-tablet">Submitted Date</th>
                            <th scope="col" width='10%' className="border-0 hide-on-tablet">Batch No.</th>
                            <th scope="col" width='10%' className="border-0">Invoice No.</th>
                            <th scope="col" width='10%' className="border-0">Job No.</th>
                            <th scope="col" width='15%' className="border-0 hide-on-mobile">Agent</th>
                            <th scope="col" width='10%' className="border-0 hide-on-tablet">Amount</th>
                            <th scope="col" width='25%' className="border-0">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.data.map((row, idx) => {
                            var list = this.state.selected
                            var dates = this.state.dates
                            const checked = this.state.selected.find(x => x === row.uuid)
                            return (
                                <tr id={row['uuid']} key={row['uuid']} className='py-1'>
                                    <td className='hide-on-mobile pt-2 pl-3'>
                                        <div className='mx-3 mt-1 mb-0 px-2 py-1'>
                                            <FormCheckbox
                                                checked={!!checked}
                                                style={{ width: '4rem' }}
                                                value={!!checked}
                                                onChange={() => {
                                                    if (!!checked) {
                                                        list = list.filter(item => item !== row['uuid'])
                                                        dates = dates.filter(item => item !== row['createdAt'])
                                                        this.setState({
                                                            selected: list
                                                        })
                                                        this.props.onSelected(list, dates)
                                                    } else {
                                                        list.push(row.uuid)
                                                        dates.push(row.createdAt)
                                                        this.setState({
                                                            selected: list
                                                        })
                                                        this.props.onSelected(list, dates)
                                                    }
                                                }}
                                            />
                                        </div>
                                    </td>
                                    <td className='hide-on-tablet pt-4'>
                                        <div className='px-1'>
                                            {moment(row['submitted_at']).format('YYYY-MM-DD HH:mm')}
                                        </div>
                                    </td>
                                    <td className='hide-on-tablet py-4'>{row['batch_no']}</td>
                                    <td className='pt-4'>{row['invoice_no']}</td>
                                    <td className='pt-4'>{row['job_no']}</td>
                                    <td className='hide-on-mobile py-4'>
                                        {row['agent'] ? row['agent']['full_name'] : ''}
                                    </td>
                                    <td className='hide-on-tablet py-4'>R {parseFloat(row['amount'] || 0).toFixed(2)}</td>
                                    <td>
                                        <ButtonGroup size="md" className="mr-0 mt-1">
                                            <Button
                                                className='btn-yellow'
                                                type='button'
                                                onClick={() => this.props.onShowRevertPopup(row['details'])}
                                            >
                                                Revert
                                            </Button>
                                            <Button
                                                id={`btn-submit-${idx}`}
                                                className='btn-green'
                                                type='button'
                                                onClick={() => {
                                                    const btn = document.getElementById(`btn-submit-${idx}`)
                                                    btn.disabled = true
                                                    this.setState({ name: `${row['batch_no']}_${row['job_no']}` })
                                                    this.downloadInvoice(row, btn)
                                                }}
                                            >
                                                Download
                                            </Button>
                                        </ButtonGroup>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <div className='bg-light d-flex text-center mt-2 px-3' style={{ width: '100%', height: '2rem', display: 'none' }}>
                    <div className='mx-2'>
                        <p align="center" valign="center" style={{ lineHeight: '2rem' }}>
                            Displaying {((page - 1) * 12) + 1} to {max} {count ? ` of ${count} ` : ''} results
                        </p>
                    </div>
                    <ButtonGroup className="mr-2 ">
                        {(page > 1) && <Button theme='secondary' type='button' onClick={() => this.props.onPrev()}>Prev</Button>}
                        <div style={{ width: '8px' }} />
                        {(count >= page * 12) && <Button theme='secondary' type='button' onClick={() => this.props.onNext()}>Next</Button>}
                    </ButtonGroup>
                </div>
            </CardBody>
        )
    }

    renderArchived() {
        const page = this.props.page
        const count = this.state.count
        const multiplier = count < 12 ? count : 12
        const max = count < page * multiplier ? count : page * multiplier

        if (this.props.data && this.props.data.length === 0) {
            return (
                <Container fluid className="main-content-container px-4 pb-4">
                    <div className="error__content mt-4">
                        <h2>Empty</h2>
                        <h3>No Invoices Found</h3>
                        <p>It could be that there are no complete service calls. </p>
                    </div>
                </Container>
            );
        }

        return (
            <CardBody className="p-0 pb-3">
                <table className="table mb-0">
                    <thead className="bg-light">
                        <tr>
                            <th scope="col" width='15%' className="border-0 hide-on-tablet">Archived Date</th>
                            <th scope="col" width='10%' className="border-0 hide-on-tablet">Batch No.</th>
                            <th scope="col" width='10%' className="border-0">Invoice No.</th>
                            <th scope="col" width='10%' className="border-0">Job No.</th>
                            <th scope="col" width='20%' className="border-0">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.data.map((row, idx) => {
                            return (
                                <tr id={row['uuid']} key={row['uuid']} className='py-1'>
                                    <td className='hide-on-tablet pt-4'>
                                        <div className='px-1'>
                                            {moment(row['archived_at']).format('YYYY-MM-DD HH:mm')}
                                        </div>
                                    </td>
                                    <td className='hide-on-tablet py-4'>{row['batch_no']}</td>
                                    <td className='pt-4'>{row['invoice_no']}</td>
                                    <td className='pt-4'>{row['job_no']}</td>
                                    <td>
                                        <ButtonGroup size="md" className="mr-0 ">
                                            <Button
                                                id={`btn-submit-${idx}`}
                                                className='btn-green'
                                                onClick={() => {
                                                    const btn = document.getElementById(`btn-submit-${idx}`)
                                                    btn.disabled = true
                                                    this.setState({ name: `${row['batch_no']}_${row['job_no']}` })
                                                    this.downloadInvoice(row, btn)
                                                }}
                                            >
                                                Download
                                            </Button>
                                            <Button
                                                className='btn-yellow'
                                                onClick={() => {
                                                    this.props.onClearScroll()
                                                    this.props.history.push(`/invoices/${row['uuid']}/view`, {
                                                        row: row,
                                                        currentState: this.props.currentState,
                                                        scroll: row['uuid'],
                                                        returnTo: '/archives'
                                                    })
                                                }}
                                            >
                                                View
                                            </Button>
                                        </ButtonGroup>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <div className='bg-light d-flex text-center mt-2 px-3' style={{ width: '100%', height: '2rem', display: 'none' }}>
                    <div className='mx-2'>
                        <p align="center" valign="center" style={{ lineHeight: '2rem' }}>
                            Displaying {((page - 1) * 12) + 1} to {max} {count ? ` of ${count} ` : ''} results
                        </p>
                    </div>
                    <ButtonGroup className="mr-2 ">
                        {(page > 1) && <Button theme='secondary' type='button' onClick={() => this.props.onPrev()}>Prev</Button>}
                        <div style={{ width: '8px' }} />
                        {(count >= page * 12) && <Button theme='secondary' type='button' onClick={() => this.props.onNext()}>Next</Button>}
                    </ButtonGroup>
                </div>
            </CardBody>
        )
    }

    render() {
        if (this.state.status === 'archived') {
            return this.renderArchived()
        }

        if (this.state.status === 'invoice_submitted') {
            return this.renderSubmitted()
        }

        return this.renderGenerated()
    }
}

export default withRouter(InvoiceTable)
